//IMPORTS
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Send from '~icons/carbon/send';
import SendFilled from '~icons/carbon/send-filled';
import View from '~icons/carbon/view';
import ViewOff from '~icons/carbon/view-off';

//EXPORT
/**
 * Formatted input component
 *
 * @remarks
 * Must be hydrated for active styling
 * @param button - Includes an inline submit or password view button
 */
export default function Input({
	inline,
	button,
	className,
	style,
	type = 'text',
	children,
	disabled,
	...attrs
}: {
	button: 'password' | 'submit';
	className: string;
	style: string;
	type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
	children: React.ReactNode;
	placeholder?: string;
	name?: string;
	disabled?: boolean;
	required?: boolean;
	inline: boolean;
}) {
	//USESTATES
	const [inputFocused, setInputFocused] = useState(false);
	const [passwordView, setPasswordView] = useState(true);

	return (
		<div
			className={twMerge(
				//INLINE STYLING
				inline ? 'border-x-0 border-y' : 'rounded',
				//COLOUR STYLING
				style === 'secondary' ?
					'border-secondary-700/50 bg-secondary-50 text-secondary-700 hover:border-secondary-700'
				:	'border-primary-700/50 bg-primary-50 hover:border-primary-700',
				//FOCUSED STYLING corresponding to colour
				inputFocused && 'border-2',
				inputFocused && (style === 'secondary' ? 'border-secondary-700' : 'border-primary-700'),
				'bg-grow peer flex w-full items-center border [[disabled]&]:bg-gray-100',
				//DISABLED STYLING corresponding to colour
				disabled &&
					(style === 'secondary' ?
						'cursor-not-allowed border-secondary-300/20 text-secondary-300/20 hover:border-secondary-300/20'
					:	'cursor-not-allowed border-primary-700/20 text-primary-700/20 hover:border-primary-700/20'),
				//CUSTOM STYLING
				className,
			)}
		>
			{/* FORMATTED INPUT FIELD */}
			<input
				className={twMerge(
					'w-full border-0 bg-transparent ring-0 focus:border-0 focus:ring-0 active:border-0 active:ring-0 [&::placeholder]:text-ellipsis',
					//COLOUR STYLING
					style === 'secondary' ?
						'text-secondary-700 placeholder:text-secondary-700/50'
					:	'text-primary-700 placeholder:text-primary-700/50',
					//DISABLED STYLING
					disabled && 'cursor-not-allowed text-primary-700/40 placeholder:text-primary-700/40',
				)}
				{...attrs}
				type={
					type == 'password' ?
						passwordView ?
							'password'
						:	'text'
					:	type
				}
				disabled={disabled}
				autoCapitalize='none'
				onFocus={() => setInputFocused(true)}
				onBlur={() => setInputFocused(false)}
			/>
			{/* VIEW PASSWORD BUTTON */}
			{button === 'password' && (
				<div
					className={twMerge(
						'inline px-2 text-inherit',
						inputFocused ?
							style == 'secondary' ?
								'text-secondary-700'
							:	'text-primary-700'
						: style == 'secondary' ? 'text-secondary-700/50'
						: 'text-primary-700/50',
						disabled && 'text-primary-700/40',
					)}
				>
					{passwordView ?
						<ViewOff onClick={() => setPasswordView(false)} />
					:	<View onClick={() => setPasswordView(true)} />}
				</div>
			)}
			{/* SUBMIT BUTTON */}
			{button === 'submit' && (
				<button
					disabled={disabled}
					type='submit'
					className={twMerge(
						'group my-1 mr-2 !bg-transparent !text-primary-700 !shadow-none',
						disabled && 'text-primary-700/40',
					)}
				>
					{/* DEFAULT BUTTON */}
					<span className={twMerge('flex h-full items-center', !disabled && 'group-hover:hidden')}>
						<Send
							className={twMerge(
								'inline align-[-.16em] text-2xl',
								disabled && 'cursor-not-allowed text-primary-700/40',
							)}
						/>
					</span>
					{/* ACTIVE BUTTON */}
					<span className={twMerge('hidden h-full items-center', !disabled && 'group-hover:flex')}>
						<SendFilled
							className={twMerge(
								'inline align-[-.16em] text-2xl',
								disabled && 'cursor-not-allowed text-primary-700/40',
							)}
						/>
					</span>
				</button>
			)}
		</div>
	);
}
